.slider, .slider_full {
  margin-top: 10px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(116, 116, 116, 0.25);
  grid-area: slider;
  width: 100%;
  max-width: 854px;
  aspect-ratio: 20 / 9;

  [class*='slick-list'] {
    height: 100% !important;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  [class*='slick-track'] {
    display: flex !important;
    height: 100% !important;
  }

  [class*='slick-slide'] {
    height: 100% !important;
    width: 100% !important;
  }

  [class*='slick-active'],
  [class*='slick-current'] {
    height: 100% !important;
    width: 100% !important;
  }

  [class*='slick-slide'] a {
    height: 100%;
    width: 100%;
    text-decoration: none;
  }

  [class*='slick-slide'] div {
    height: 100%;
    width: 100%;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 25;
    cursor: pointer;
    display: none;
  }

  .next_arrow {
    right: 10px;
  }

  .prev_arrow {
    left: 10px;
  }

  &:hover {
    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 1024px) {
  .slider, .slider_full {
    min-height: 100%;
  }
}

@media screen and (max-width: 480px) {
  .slider, .slider_full {
    margin: auto;

    .dots {
      bottom: 12px;
    }

    .arrow {
      display: none !important;
    }
  }
}

@media (max-width: 375px) {
  .slider, .slider_full {
    aspect-ratio: 2.2 / 1;
  }
}

.slider_full {
  max-width: 100%;
}