@import '@/styles/variables/mixins/media-queries';

.banner_and_category_block {
  width: 100%;
  margin-top: 12px;
  display: grid;
  grid-template-areas:
      'event_block slider'
      'event_block category_block';
  gap: var(--bzr-spacing-premedium);

  [class*="banner_slider"] {
    margin-top: 0;
  }
}

@include bzr-breakpoint('tablet') {
  .banner_and_category_block {
    display: flex;
    flex-direction: column;

    .event_and_category {
      display: flex;
      gap: var(--bzr-spacing-premedium);
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }
}