@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/media-queries';

.header {
  display: flex;
  justify-content: center;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--bzr-spacing-large);
  width: 311px;
  margin: var(--bzr-spacing-large) 0 var(--bzr-spacing-large-44);

  h2 {
    @include --bzr-h5();
    color: var(--bzr-text-dark-black-primary);
    text-align: center;
  }
}

@include bzr-breakpoint('mobile') {
  .content {
    width: unset;
  }
}