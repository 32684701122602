@mixin font-style($font-size, $font-weight, $line-height, $color, $text-align) {
  font-family: Montserrat;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color;
  text-align: $text-align;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.info_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  border: 1px solid #56b947;
  border-radius: var(--bzr-spacing-xs);
  margin-bottom: var(--bzr-spacing-large);
  overflow: hidden;

  p {
    white-space: nowrap;
    @include font-style(16px, 500, 19.5px, var(--bzr-main-blue-default), center);

    b {
      font-weight: 700;
    }
  }

  @media (max-width: 1200px) {
    justify-content: unset;
    p {
      padding-left: 100%;
      animation: marquee 12s linear infinite;
    }
  }
}

.seo-links {
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.main_title {
  margin-top: 80px;
  display: grid;
  grid-template-columns: auto;
  grid-template-areas:
    'title'
    'subtitle'
    'second-subtitle';
  gap: 6px;

  .title {
    grid-area: title;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    @include font-style(40px, 600, 48.76px, #252528, center);

    .number {
      width: 84px;
      height: 56px;
      padding: 6px 16px;
      border-radius: 8px;
      background: #47c612;
      @include font-style(32px, 600, 39.01px, #ffff, left);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .subtitle {
    grid-area: subtitle;
    @include font-style(40px, 600, 48.76px, #252528, center);
  }

  .second_subtitle,
  .second_subtitle_ru {
    grid-area: second-subtitle;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    @include font-style(40px, 600, 48.76px, #252528, center);

    .two_thousand {
      width: 135px;
      height: 56px;
      padding: 6px 16px;
      border-radius: 8px;
      background: #0093db;
      @include font-style(32px, 600, 39.01px, #ffff, left);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 1024px) {
  .main_title {
    .title,
    .subtitle,
    .second_subtitle,
    .second_subtitle_ru {
      font-size: 32px;
    }
  }

  .info_banner {
    margin-bottom: var(--bzr-spacing-small);
  }
}

@media (max-width: 768px) {
  .main_title {
    .title,
    .subtitle,
    .second_subtitle,
    .second_subtitle_ru {
      font-size: 26px;

      .number {
        font-size: 24px;
        width: 60px;
        height: 40px;
        padding: 0px 10px;
      }

      .two_thousand {
        width: 100px;
        height: 45px;
        padding: 4px 12px;
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 480px) {
  .main_title {
    justify-items: center;

    .title,
    .subtitle,
    .second_subtitle,
    .second_subtitle_ru {
      font-size: 14px;
      line-height: 17.07px;
      text-align: center;
      gap: 8px;

      .number {
        width: 32px;
        height: 22px;
        padding: 3.5px 6px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 14.63px;
        text-align: left;
      }

      .two_thousand {
        width: 52px;
        height: 22px;
        padding: 3.5px 6px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 14.63px;
      }
    }

    .second_subtitle_ru {
      gap: 8px;
      align-items: flex-start;
      width: 249px;
      height: 43px;
      text-align: left;
      word-break: break-all;

      .text {
        width: 189px;
        font-size: 14px;
        font-weight: 600;
        line-height: 22.07px;
        text-align: left;
      }
    }
  }
}
